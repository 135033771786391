(function () {
    'use strict';

    angular.module('adminApp')
        .factory('AddAdminVendorLogoModal', AddAdminVendorLogoModal)
        .controller('AddAdminVendorLogoController', AddAdminVendorLogoController);

    AddAdminVendorLogoModal.$inject = ['$uibModal'];

    function AddAdminVendorLogoModal($uibModal) {
        function open(vendorModel) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    vendorModel: function () {
                        return vendorModel;
                    }
                },
                templateUrl: "/static/templates/admin/modals/AddAdminVendorLogoModal.html",
                controller: "AddAdminVendorLogoController"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }

    AddAdminVendorLogoController.$inject = ['$scope', '$uibModalInstance', 'Notification', 'aerosAdminApi', 'vendorModel'];

    function AddAdminVendorLogoController($scope, $uibModalInstance, Notification, aerosAdminApi, vendorModel) {
      $scope.vendorModel = vendorModel;
      $scope.file = null;
      $scope.$watch(
          function() { return $('#fileSelector').val(); },
          function(newValue, oldValue) {
              if (!newValue || $('#fileSelector').length === 0 || $('#fileSelector')[0].files.length === 0) return;
              var fileData = $('#fileSelector')[0].files[0];
              var reader = new FileReader();
              reader.onload = function (e) {
                  $('#temp-img').attr({'src': e.target.result});
              };
              reader.readAsDataURL(fileData);
          }
      );

        angular.extend($scope, {
            addLogo: addLogo,
            deleteLogo: deleteLogo,
            vendorModel: vendorModel
        });

        function addLogo() {
            if (!$scope.file) return;

            aerosAdminApi.vendorApi.addLogo($scope.vendorModel.id, $scope.file).then(function (results) {
                Notification.success("Vendor Logo succesfully added/updated");
                $uibModalInstance.close($scope.file);
            }, function (error) {
                Notification.error("Vendor logo failed to add/update. " + error.data.message);
            });

        }

        function deleteLogo() {

            if ( confirm("Are you sure you want to delete the logo for " + $scope.vendorModel.name + "?") ) {
                aerosAdminApi.vendorApi.deleteLogo($scope.vendorModel.id).then(function (results) {
                    Notification.success("Vendor Logo succesfully deleted");
                    $uibModalInstance.close();
                }, function (error) {
                    Notification.error("Vendor logo failed to delete. " + error.data.message);
                });
            }
        }
    }

})();